import Footer from './footer'

export default function Layout ({ children, footer = false, ...props }) {
  return (
    <div id='animationRoot'>
      <main {...props}>
        {children}
      </main>
      {footer && (
        <Footer />
      )}
    </div>
  )
}
