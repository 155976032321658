import { Fragment, useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import Home from './components/pages/landing'
import Category from './components/pages/category'
import CaseStudy from './components/pages/caseStudy'
import Data from './content'
// import Intro from './components/pages/intro'
import Goals from './components/pages/goals'
import WebFont from 'webfontloader'

export default function App () {
  const location = useLocation()
  const [imagesLoaded, setImagesLoaded] = useState(0)
  const [imagesTotal, setImagesTotal] = useState(0)
  // const [introUrl, setIntroUrl] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)

  const cacheImages = (aImages) => {
    let l = 0
    return Promise.all(
      aImages.map((imageUrl) => {
        return new Promise((resolve, reject) => {
          const img = new window.Image()
          img.src = imageUrl
          img.onload = () => {
            setImagesLoaded(l++)
            resolve()
          }
          img.onerror = (error) => reject(error)
        })
      })
    )
  }

  useEffect(() => {
    // Pre-cache large images.

    const init = async () => {
      // setIntroUrl('https://stream.mux.com/301IHv01GjXxZupTmvHXaxWWuqLqrUjMSDN802dXtRC2q4.m3u8')

      WebFont.load({
        custom: {
          families: [
            'helvetica_lt_sultracompressed',
            'helvetica_neue_lt_std77BdCn',
            'helvetica_neue_lt_std87HvCn',
            'helvetica_neue_lt_std67MdCn',
            'scala_sans_otblack',
            'zapf_dingbatsregular',
            'helvetica_neue_lt_std67MdCn'
          ]
        }
      })

      console.log('Loading Images...')
      let images = [
        '/images/people/people.jpg',
        '/images/community/community.jpg',
        '/images/environment/environment.jpg',
        '/images/home/1_1.jpg',
        '/images/home/1_2.jpg',
        '/images/home/1_3.jpg',
        '/images/home/2_1.jpg',
        '/images/home/2_2.jpg',
        '/images/home/2_3.jpg',
        '/images/home/3_1.jpg',
        '/images/home/3_2.jpg',
        '/images/home/3_3.jpg',
        '/images/home/button.png',
        '/images/home/logo.png',
        '/images/play.png',
        '/images/tiles/E-WEB-Goal-01.png',
        '/images/tiles/E-WEB-Goal-02.png',
        '/images/tiles/E-WEB-Goal-03.png',
        '/images/tiles/E-WEB-Goal-04.png',
        '/images/tiles/E-WEB-Goal-05.png',
        '/images/tiles/E-WEB-Goal-06.png',
        '/images/tiles/E-WEB-Goal-07.png',
        '/images/tiles/E-WEB-Goal-08.png',
        '/images/tiles/E-WEB-Goal-09.png',
        '/images/tiles/E-WEB-Goal-10.png',
        '/images/tiles/E-WEB-Goal-11.png',
        '/images/tiles/E-WEB-Goal-12.png',
        '/images/tiles/E-WEB-Goal-13.png',
        '/images/tiles/E-WEB-Goal-14.png',
        '/images/tiles/E-WEB-Goal-15.png',
        '/images/tiles/E-WEB-Goal-16.png',
        '/images/tiles/E-WEB-Goal-17.png'
      ]

      const caseStudyImages = Data.flatMap((cat) => {
        return cat.caseStudies.map((caseStudy) => {
          return window.innerWidth > 768 || !caseStudy.imageMobile ? caseStudy.image : caseStudy.imageMobile
        })
      })

      images = images.concat(caseStudyImages)

      setImagesTotal(images.length)
      await cacheImages(images)
      console.log('Images Loaded.')

      setTimeout(() => {
        setIsLoaded(true)
      }, 1000)
    }

    init()
  }, [])

  return (
    <>
      {!isLoaded && (
        <div style={{ position: 'absolute', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh', backgroundColor: '#000', zIndex: 99 }}>
          Loading...
          <div style={{ width: '12rem', height: '0.55rem', border: '1px solid #fff', marginTop: '1rem' }}>
            <span style={{ display: 'block', width: `${(imagesLoaded / (imagesTotal - 1)) * 100}%`, height: '100%', backgroundColor: '#fff', transition: 'width 1s' }} />
          </div>
        </div>
      )}
      <AnimatePresence mode='wait'>
        <Routes location={location} key={location.pathname}>
          {/* <Route path='/' index element={<Intro active={isLoaded} src={introUrl} />} /> */}
          <Route path='/' element={<Home />} />
          {Data.map((data, i) => (
            <Fragment key={`route-${i}`}>
              <Route path={data.path} element={<Category data={data} />} />
              <Route path={`${data.path}/goals`} element={<Goals data={data} />} />
              {data.caseStudies?.map((cs, c) => (
                <Route key={`route-${i}-${c}`} path={`${data.path}/${c}`} element={<CaseStudy id={`${data.name.toLowerCase()}Case${c}`} data={cs} />} />
              ))}
            </Fragment>
          ))}
        </Routes>
      </AnimatePresence>
    </>
  )
}
