import Layout from '../shared/layout'
import { motion } from 'framer-motion'
import '../../styles/pages/category.scss'
import StaggeredAnimation from '../shared/staggeredAnimation'
import { Link } from 'react-router-dom'

export default function Category ({ data }) {
  return (
    <Layout id={data.name.toLowerCase()} className='category-page' footer>
      <motion.img initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='background' src={data.background} alt='' />
      <div className='container'>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='left'>
          <motion.h1 initial={{ opacity: 0, paddingTop: '1rem' }} animate={{ opacity: 0.85, paddingTop: 0, transition: { delay: 0.5 } }} exit={{ opacity: 0 }}>{data.name}</motion.h1>
          <Link to={`${data.path}/goals`}>
            <StaggeredAnimation className='tiles' delay={0.75}>
              {data.goals.map((goal, i) => (
                <img key={`goal-${i}`} src={`/images/tiles/E-WEB-Goal-${goal}.png`} alt='' />
              ))}
            </StaggeredAnimation>
          </Link>
        </motion.div>
        {(data.heading || data.content) && (
          <motion.div className='right' initial={{ opacity: 0, marginTop: '1vw' }} animate={{ opacity: 0.85, marginTop: 0, transition: { delay: 1.5 } }} exit={{ opacity: 0 }}>
            <h2>{data.heading}</h2>
            <p>{data.content}</p>
          </motion.div>
        )}
      </div>
    </Layout>
  )
}
