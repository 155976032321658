import { useEffect, useState } from 'react'
import Layout from '../shared/layout'
import '../../styles/pages/caseStudy.scss'
import { motion } from 'framer-motion'
import StaggeredAnimation from '../shared/staggeredAnimation'

export default function CaseStudy ({ id, data }) {
  const [windowWidth, setWindowWidth] = useState(null)

  const formatContent = (str) => {
    let insideList = false
    let listItems = []
    const paragraphs = []

    const replaceChars = (text) => text.replace(/-/g, '\u2011').replace(/italic\((.*?)\)/g, '<i>$1</i>')

    str.split(/\n\n/).forEach((paragraph, index, array) => {
      if (paragraph.startsWith('•')) {
        let bulletPoint = replaceChars(paragraph.substring(1)).trim()
        bulletPoint = bulletPoint.split(/•'|• /)
        bulletPoint.forEach((listItem, i) => {
          listItems.push(<li key={`li${index}-${i}`} dangerouslySetInnerHTML={{ __html: listItem }} />)
        })

        if (index === array.length - 1) {
          insideList = false
          paragraphs.push(<ul key={`ul${index}`}>{listItems}</ul>)
        }

        insideList = true
      } else {
        if (insideList) {
          insideList = false
          paragraphs.push(<ul key={`ul${index}`} className={`${listItems.length > 7 ? 'gt7' : ''}`}>{listItems}</ul>)
          listItems = []
        }

        paragraphs.push(<p key={`p${index}`} dangerouslySetInnerHTML={{ __html: replaceChars(paragraph) }} />)
      }
    })

    return paragraphs
  }

  useEffect(() => {
    function handleResize () {
      setWindowWidth(window.innerWidth)
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('keydown', handleResize)
    }
  }, [])

  return (
    <Layout id={id} className='case-study' footer>
      <div className='container'>
        <div className='left'>
          <motion.h1 initial={{ opacity: 0, paddingTop: '0.5rem' }} animate={{ opacity: 1, paddingTop: 0 }} exit={{ opacity: 0 }}>
            {data.location && (<span>{data.location}</span>)}
            {data.heading}
          </motion.h1>
          <StaggeredAnimation delay={0.25}>
            {formatContent(data.content)}
          </StaggeredAnimation>
        </div>
        <div className='right'>
          <motion.div className='content' initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <div className={`tiles${data.goals.length > 2 ? ' gt2' : ''}`}>
              {data.goals.map((goal, i) => (
                <motion.img key={`cs-goal-${i}`} src={`/images/tiles/E-WEB-Goal-${goal}.png`} alt='' initial={{ opacity: 0, marginTop: '0.5rem' }} animate={{ opacity: 1, marginTop: 0, transition: { delay: (i * 0.25) + 0.5 } }} exit={{ opacity: 0 }} />
              ))}
            </div>
            {/*
                Had to make this an image because of font issues, numbers will not display properly.
                Now being used for mobile sizes, no other solution.
            */}
            {data.altContent && (
              <ul>
                {data.altContent.map((item, i) => (
                  <li key={`alt-${i}`}>{item}</li>
                ))}
              </ul>
            )}
          </motion.div>
          <motion.img src={windowWidth > 768 ? data.image : data.imageMobile ? data.imageMobile : data.image} alt='' initial={{ opacity: 0, paddingTop: '0.5rem' }} animate={{ opacity: 1, paddingTop: 0 }} exit={{ opacity: 0 }} />
        </div>
      </div>
    </Layout>
  )
}
